<template>
	<div class="inspectData">
		<div class="main-filter">
			<el-form inline :model="formInline" class="demo-form-inline">
				<el-form-item label="省：">
					<el-select
						v-model="formInline.economize"
						:disabled="userInfo.regionCode && userInfo.regionCode.length >= 2"
						placeholder="请选择"
						@change="changeEconomize($event, 1)"
					>
						<el-option v-for="item in economizeList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="市：">
					<el-select
						v-model="formInline.market"
						:disabled="userInfo.regionCode && userInfo.regionCode.length >= 4"
						placeholder="请选择"
						@change="changeEconomize($event, 2)"
					>
						<el-option v-for="item in marketList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="区县：">
					<el-select
						v-model="formInline.district"
						:disabled="userInfo.regionCode && userInfo.regionCode.length >= 6"
						placeholder="请选择"
						@change="changeEconomize($event, 3)"
					>
						<el-option v-for="item in districtList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="乡镇：">
					<el-select
						v-model="formInline.townCode"
						:disabled="userInfo.regionCode && userInfo.regionCode.length >= 9"
						placeholder="请选择"
						@change="changeEconomize($event, 4)"
					>
						<el-option v-for="item in townList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="村：">
					<el-select v-model="formInline.villageCode" :disabled="userInfo.regionCode && userInfo.regionCode.length >= 12" placeholder="请选择">
						<el-option v-for="item in villageList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getData" icon="el-icon-search">搜索</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="container">
			<div class="inspectData-title">
				<div class="inspectData-title-content clearfix">
					<div class="institutionTotal float-l">
						<img src="@/assets/imgs/institution.png" alt="" />
						<div class="institutionTotal-content">
							<p>检查检验人数</p>
							<p>{{ datas.examinationCount }}</p>
						</div>
					</div>
					<div class="institutionTotal float-l mr0">
						<img src="@/assets/imgs/peoples.png" alt="" />
						<div class="institutionTotal-content">
							<p>检查检验疾病种类</p>
							<p>{{ datas.diseaseCount }}</p>
						</div>
					</div>
				</div>
			</div>
			<div class="content clearfix">
				<div class="chartsClass float-l" ref="inspectHeight">
					<p>
						<img src="@/assets/imgs/datas.png" alt="" />
						检查内容图片数量统计
					</p>
					<div id="inspect" :style="{ width: '100%', height: inspectHeight + 'px' }" v-show="datas.examinationCount !== 0"></div>
					<div
						class="nullData"
						v-if="datas.examinationCount == 0"
						:style="{ width: '100%', height: inspectHeight + 'px', lineHeight: inspectHeight + 'px' }"
					>
						暂无数据
					</div>
				</div>
				<div class="chartsClass float-l" ref="titleChartsHeight">
					<p>
						<img src="@/assets/imgs/datas.png" alt="" />
						疾病TOP10
					</p>
					<div id="titleCharts" :style="{ width: '100%', height: titleChartsHeight + 'px' }" v-show="datas.diseaseCount !== 0"></div>
					<div
						class="nullData"
						v-if="datas.diseaseCount == 0"
						:style="{ width: '100%', height: titleChartsHeight + 'px', lineHeight: titleChartsHeight + 'px' }"
					>
						暂无数据
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import * as echarts from 'echarts';
export default {
	name: 'inspectData',
	data() {
		return {
			formInline: {
				economize: null,
				market: null,
				district: null,
				townCode: null,
				villageCode: null,
			},
			townList: [], //乡镇
			villageList: [], //村
			districtList: [], //区县
			marketList: [], //市
			economizeList: [], //省
			inspectHeight: 500, // 医护人员分类及人数统计图表高度
			titleChartsHeight: 500, // 职称分类及人数统计图表高度
			datas: {},
			userInfo: {},
		};
	},
	mounted() {
		this.$nextTick(() => {
			this.inspectHeight = this.$refs.inspectHeight.offsetHeight - 90 > 500 ? this.$refs.inspectHeight.offsetHeight - 90 : 500;
			this.titleChartsHeight = this.$refs.titleChartsHeight.offsetHeight - 90 > 500 ? this.$refs.titleChartsHeight.offsetHeight - 90 : 500;
		});
		this.getEconomizeList();
		this.userInfo = JSON.parse(sessionStorage.getItem('userInfo')) || {};
		switch (this.userInfo.regionCode.length) {
			case 2:
				this.formInline.economize = this.userInfo.chinaRegionDtoList[0].code != '0' ? this.userInfo.chinaRegionDtoList[0].code : null;
				this.changeEconomize(this.formInline.economize, 1);
				break;
			case 4:
				this.formInline.economize = this.userInfo.chinaRegionDtoList[0].code != '0' ? this.userInfo.chinaRegionDtoList[0].code : null;
				this.changeEconomize(this.formInline.economize, 1);
				this.formInline.market = this.userInfo.chinaRegionDtoList[1].code;
				this.changeEconomize(this.formInline.market, 2);
				break;
			case 6:
				this.formInline.economize = this.userInfo.chinaRegionDtoList[0].code != '0' ? this.userInfo.chinaRegionDtoList[0].code : null;
				this.changeEconomize(this.formInline.economize, 1);
				this.formInline.market = this.userInfo.chinaRegionDtoList[1].code;
				this.changeEconomize(this.formInline.market, 2);
				this.formInline.district = this.userInfo.chinaRegionDtoList[2].code;
				this.changeEconomize(this.formInline.district, 3);
				break;
			case 9:
				this.formInline.economize = this.userInfo.chinaRegionDtoList[0].code != '0' ? this.userInfo.chinaRegionDtoList[0].code : null;
				this.changeEconomize(this.formInline.economize, 1);
				this.formInline.market = this.userInfo.chinaRegionDtoList[1].code;
				this.changeEconomize(this.formInline.market, 2);
				this.formInline.district = this.userInfo.chinaRegionDtoList[2].code;
				this.changeEconomize(this.formInline.district, 3);
				this.formInline.townCode = this.userInfo.chinaRegionDtoList[3].code;
				this.changeEconomize(this.userInfo.regionCode, 4);
				break;
			case 12:
				this.formInline.economize = this.userInfo.chinaRegionDtoList[0].code != '0' ? this.userInfo.chinaRegionDtoList[0].code : null;
				this.changeEconomize(this.formInline.economize, 1);
				this.formInline.market = this.userInfo.chinaRegionDtoList[1].code;
				this.changeEconomize(this.formInline.market, 2);
				this.formInline.district = this.userInfo.chinaRegionDtoList[2].code;
				this.changeEconomize(this.formInline.district, 3);
				this.formInline.townCode = this.userInfo.chinaRegionDtoList[3].code;
				this.changeEconomize(this.userInfo.regionCode, 4);
				this.formInline.villageCode = this.userInfo.chinaRegionDtoList[4].code;
				break;
			default:
				break;
		}
		this.getData();
	},
	methods: {
		// 获取省数据
		getEconomizeList() {
			this.$http
				.get(this.api['ChinaRegions#index'].href)
				.then((res) => {
					if (res.data && res.data.success) {
						this.economizeList = res.data.collection || [];
						this.economizeList.unshift({
							title: '全部',
							code: null,
						});
					}
				})
				.catch((e) => {});
		},
		// 获取市/区县
		changeEconomize(data, type) {
			switch (type) {
				case 1:
					this.formInline.market = null;
					this.formInline.distinguish = null;
					this.formInline.townCode = null;
					this.formInline.villageCode = null;
					this.marketList = [];
					this.districtList = [];
					this.townList = [];
					this.villageList = [];
					if (!data || data == '') return;
					this.$http
						.get(this.api['ChinaRegions#index'].href, { params: { code: data } })
						.then((res) => {
							if (res.data && res.data.success) {
								this.marketList = res.data.collection || [];
								this.marketList.unshift({
									title: '全部',
									code: '',
								});
							}
						})
						.catch((e) => {});
					break;
				case 2:
					this.formInline.distinguish = null;
					this.formInline.townCode = null;
					this.formInline.villageCode = null;
					this.districtList = [];
					this.townList = [];
					this.villageList = [];
					if (!data || data == '') return;
					this.$http
						.get(this.api['ChinaRegions#index'].href, { params: { code: data } })
						.then((res) => {
							if (res.data && res.data.success) {
								this.districtList = res.data.collection || [];
								this.districtList.unshift({
									title: '全部',
									code: '',
								});
							}
						})
						.catch((e) => {});
					break;
				case 3:
					this.formInline.townCode = null;
					this.formInline.villageCode = null;
					this.townList = [];
					this.villageList = [];
					if (!data || data == '') return;
					this.$http
						.get(this.api['ChinaRegions#index'].href, { params: { code: data } })
						.then((res) => {
							if (res.data && res.data.success) {
								this.townList = res.data.collection || [];
								this.townList.unshift({
									title: '全部',
									code: '',
								});
							}
						})
						.catch((e) => {});
					break;
				case 4:
					this.formInline.villageCode = null;
					this.villageList = [];
					if (!data || data == '') return;
					this.$http
						.get(this.api['ChinaRegions#index'].href, { params: { code: data } })
						.then((res) => {
							if (res.data && res.data.success) {
								this.villageList = res.data.collection || [];
								this.villageList.unshift({
									title: '全部',
									code: '',
								});
							}
						})
						.catch((e) => {});
					break;
				default:
					break;
			}
		},
		// 查询条件
		getData() {
			const { economize, market, district, townCode, villageCode } = this.formInline;
			let code =
				economize && !market && !district && !townCode && !villageCode
					? economize
					: economize && market && !district && !townCode && !villageCode
					? market
					: economize && market && district && !townCode && !villageCode
					? district
					: economize && market && district && townCode && !villageCode
					? townCode
					: economize && market && district && townCode && villageCode
					? villageCode
					: null;
			this.$http
				.get(this.api['ExaminationAnalysis#index'].href, { params: { regionCode: code } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.datas = res.data.collection[0] || {};
						const { diseaseInspectionCount, examinationDiseaseCount } = res.data.collection[0];
						if (diseaseInspectionCount && JSON.stringify(diseaseInspectionCount) != '{}') {
							let dataArr = [];
							for (const key in diseaseInspectionCount) {
								dataArr.push({
									label: key,
									value: diseaseInspectionCount[key],
								});
							}
							this.getinspectData(dataArr);
						} else {
							this.getinspectData([]);
						}
						if (examinationDiseaseCount && JSON.stringify(examinationDiseaseCount) != '{}') {
							let dataArr = [];
							for (const key in examinationDiseaseCount) {
								dataArr.push({
									label: key,
									value: examinationDiseaseCount[key],
								});
							}
							this.getTitleChartsData(dataArr);
						} else {
							this.getTitleChartsData([]);
						}
					}
				})
				.catch((e) => {});
		},
		// 获取检查内容数据
		getinspectData(data) {
			let myChart = echarts.init(document.getElementById('inspect'));
			myChart.setOption({
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow',
					},
				},
				grid: {
					top: 30,
					left: 10,
					right: 10,
					bottom: 0,
					containLabel: true,
				},
				tooltip: {
					trigger: 'item',
					show: true,
				},
				xAxis: {
					data: data.map((item) => item.label),
					axisLine: {
						lineStyle: {
							color: 'rgba(81,81,81,0.3)',
						},
					},
					axisLabel: {
						color: '#999999',
						fontSize: 12,
						formatter: function (value, index) {
							var strs = value.split('');
							var str = '';
							for (var i = 0, s; (s = strs[i++]); ) {
								str += s;
								if (!(i % 5)) str += '\n';
							}
							return str;
						},
					},
				},
				yAxis: {
					type: 'value',
					name: '单位',
					nameTextStyle: {
						color: '#999999',
						fontSize: 12,
						align: 'right',
					},
					axisLine: {
						lineStyle: {
							color: '#fff',
						},
					},
					axisLabel: {
						color: '#999999',
						fontSize: 12,
					},
					splitLine: {
						lineStyle: {
							color: 'rgba(81,81,81,0.3)',
							type: 'dashed',
						},
					},
				},
				series: [
					{
						type: 'bar',
						barWidth: 18,
						itemStyle: {
							normal: {
								color: '#1db9b1',
							},
						},
						data: data.map((item) => item.value),
					},
				],
			});
			window.addEventListener('resize', () => {
				myChart.resize();
			});
		},
		// 获取疾病TOP10数据
		getTitleChartsData(data) {
			let myChart = echarts.init(document.getElementById('titleCharts'));
			myChart.setOption({
				legend: {
					show: false,
				},
				grid: {
					top: 0,
					left: 32,
					right: 50,
					bottom: 0,
					containLabel: true,
				},
				tooltip: {
					trigger: 'item',
					show: true,
				},
				xAxis: {
					type: 'value',
					name: '单位',
					nameTextStyle: {
						color: '#999999',
						fontSize: 12,
						align: 'left',
						padding: [40, 0, 0, 10],
					},
					data: data.map((item) => item.value).reverse(),
					splitLine: {
						lineStyle: {
							color: '#E5E6EB',
							type: 'dashed',
						},
					},
					axisTick: {
						show: false,
					},
					axisLine: {
						//  改变x轴颜色
						lineStyle: {
							color: '#ffffff',
						},
					},
					axisLabel: {
						//  改变x轴字体颜色和大小
						textStyle: {
							color: '#666666',
							fontSize: 12,
							lineHeight: 25,
						},
					},
				},
				yAxis: {
					type: 'category',
					data: data.map((item) => item.label).reverse(),
					splitLine: {
						show: false,
					},
					axisTick: {
						show: false,
					},
					axisLine: {
						//  改变y轴颜色
						lineStyle: {
							color: '#C9CDD4',
						},
					},
					axisLabel: {
						color: '#666666',
						fontSize: 12,

						rich: {
							'01': {
								height: 40,
								align: 'center',
								backgroundColor: {
									image: '../../../assets/imgs/number01.png',
								},
							},
							'02': {
								height: 40,
								align: 'center',
								backgroundColor: {
									image: '../../../assets/imgs/number02.png',
								},
							},
							'03': {
								height: 40,
								align: 'center',
								backgroundColor: {
									image: '../../../assets/imgs/number03.png',
								},
							},
						},
					},
				},
				series: [
					{
						type: 'bar',
						name: '疾病TOP10',
						barWidth: 16,
						itemStyle: {
							normal: {
								label: {
									show: false, //开启显示
									position: 'right', //在上方显示
									textStyle: {
										//数值样式
										color: 'rgba(250,250,250,0.6)',
										fontSize: 16,
										fontWeight: 600,
									},
								},
								color: '#1db9b1',
							},
						},
						data: data.map((item) => item.value).reverse(),
					},
				],
			});
			window.addEventListener('resize', () => {
				myChart.resize();
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.inspectData {
	.container {
		margin: 16px;
	}
	.demo-form-inline {
		/deep/ .el-select {
			width: 106px !important;
			.el-input {
				width: 106px !important;
				.el-input__inner {
					width: 106px !important;
				}
			}
		}
		/deep/ .el-input {
			width: 106px;
		}
	}
	&-title {
		padding: 16px;
		background-color: #fff;
		border-radius: 4px;
		margin-bottom: 12px;

		&-content {
			.institutionTotal {
				display: flex;
				align-items: center;
				width: calc(50% - 6px);
				margin-right: 12px;
				background: #f9fafc;
				border-radius: 4px;
				padding: 16px;
				img {
					margin-right: 16px;
				}
				&-content {
					p {
						color: #999;
						font-size: 14px;
						height: 14px;
						line-height: 14px;
						&:last-child {
							font-size: 24px;
							line-height: 24px;
							height: 24px;
							margin-top: 12px;
							color: #333;
						}
					}
				}
			}
		}
	}
	.content {
		.chartsClass {
			width: calc(50% - 6px);
			background-color: #fff;
			margin-bottom: 12px;
			padding: 20px 16px;
			border-radius: 4px;
			margin-right: 12px;
			&:last-child {
				margin-bottom: 0;
				margin-right: 0;
			}
			p {
				font-size: 16px;
				line-height: 28px;
				font-weight: 500;
				color: #333333;
				margin-bottom: 22px;
				display: flex;
				align-items: center;
				img {
					margin-right: 12px;
				}
			}
		}
	}
	.mr0 {
		margin-right: 0 !important;
	}
	.nullData {
		text-align: center;
		color: #999999;
		font-size: 20px;
	}
}
</style>